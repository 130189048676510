import {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
import './style.css'
import {LANGUAGES} from "../../../constants";

function CreateKeyModal({accessToken, show, close, onSubmit, onUpdate}) {
    const initialAccessKey = {
        title: "",
        lng: LANGUAGES.hy,
        startDate: undefined,
        endDate: undefined
    }
    const [accessKey, setAccessKey] = useState(accessToken ? accessToken : initialAccessKey)

    const handleInputChange = (e) => {
        const {name, value} = e.target
        setAccessKey({...accessKey, [name]: value})
    }

    const handleDateChange = (date, name) => {
        setAccessKey({...accessKey, [name]: date});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (accessToken) {
            await onUpdate(accessKey)
        } else {
            await onSubmit(accessKey)
        }
        setAccessKey(initialAccessKey)
        close();
    }

    return (
        <>
            <Modal show={show} onHide={close} centered className="access-key-modal">
                <Modal.Header closeButton>
                    <Modal.Title className="roboto">Create access key</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicTitle">
                            <Form.Label className="roboto">Title</Form.Label>
                            <Form.Control
                                required
                                className="roboto"
                                type="text"
                                placeholder="Enter access key title"
                                name="title"
                                value={accessKey.title}
                                onChange={handleInputChange}
                            />
                            <Form.Text className="text-muted roboto">
                                Enter name for your new key
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicLng">
                            <Form.Label className="roboto">Default language</Form.Label>
                            <Form.Select
                                required
                                name="lng"
                                onChange={handleInputChange}
                                placeholder="Choose film default language"
                                value={accessKey.lng}
                            >
                                <option value={LANGUAGES.hy}>ARM</option>
                                <option value={LANGUAGES.en}>ENG</option>
                            </Form.Select>
                            <Form.Text className="text-muted roboto">
                                Choose film default language
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicToken">
                            <Form.Label className="roboto">Start date</Form.Label>
                            <DatePicker
                                required
                                selected={accessKey.startDate}
                                onChange={(e) => handleDateChange(e, "startDate")}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="yyyy-MM-dd hh:mm aa"
                                wrapperClassName="date-time-picker"
                                className="form-control"
                                style={{
                                    display: 'block'
                                }}
                            />
                            <Form.Text className="text-muted roboto">
                                Choose access token start date
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicToken">
                            <Form.Label className="roboto">End date</Form.Label>
                            <DatePicker
                                required
                                selected={accessKey.endDate}
                                onChange={(e) => handleDateChange(e, "endDate")}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="yyyy-MM-dd hh:mm aa"
                                wrapperClassName="date-time-picker"
                                className="form-control"
                                style={{
                                    display: 'block'
                                }}
                            />
                            <Form.Text className="text-muted roboto">
                                Choose access token end date
                            </Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="submit" className='roboto'>
                            Save
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CreateKeyModal;