import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

const RotateDevice = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="rotate-device">
        <div className="rotate-device-title">
          <span className={`${i18n.language === LANGUAGES.en ? 'wire-one' : ''}`}>
            {t('rotate_device.interactive')}
          </span>
          <span className={`orange ${i18n.language === LANGUAGES.en ? 'wire-one' : ''}`}>
            &nbsp;{t('rotate_device.film')}
          </span>
        </div>
        <div className="mb-5">
          <div className="rotate-device-icon"></div>
        </div>
        <div className="rotate-device-text mx-5">
          <span className={`${i18n.language === LANGUAGES.en ? 'wire-one' : ''}`}>
            {t('rotate_device.rotate')}
          </span>
        </div>
      </div>
    </>
  );
};

export default RotateDevice;
