import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

const ContactForm = ({ start, handleChangeContactInfo }) => {
  const [age, setAge] = useState(10);
  const [step, setStep] = useState(0);
  const [gender, setGender] = useState('');
  const ref = useRef(null);

  const { t, i18n } = useTranslation();

  const handleAgeChange = (e) => {
    const value = e.target.value;
    setAge(value);
    handleChangeContactInfo(e);
  };

  const handleGenderChange = (e) => {
    const value = e.target.value;
    setGender(value);
    handleChangeContactInfo(e);
  };

  useEffect(() => {
    const rangeLinePadding = 0;
    const calcStep =
      (ref.current.offsetWidth - rangeLinePadding) / (ref.current.max - ref.current.min);
    setStep(calcStep);
  }, []);

  return (
    <>
      <div className="contact-form d-flex flex-column show-animation">
        <div className="form">
          <form action="" className="contact-form-form">
            <div className="age-form">
              <label htmlFor="age" className="field-title montserrat-arm">
                {t('contact_form.age')}
              </label>
              <div className="age-range-block">
                <span className="age-range">10</span>
                <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                  <input
                    ref={ref}
                    value={age}
                    onChange={handleAgeChange}
                    type="range"
                    id="age"
                    name="age"
                    min="10"
                    max="18"
                  />
                  <label
                    htmlFor="age"
                    style={{
                      transform: `translateX(${age === 0 ? 0 : age * step - step * 10}px)`
                    }}
                  >
                    <span> {age} </span>
                  </label>
                </div>
                <span className="age-range">18+</span>
              </div>
            </div>
            <div>
              <span className="field-title montserrat-arm">{t('contact_form.gender')}</span>
              <div
                className={`age-range-block ${
                  i18n.language === LANGUAGES.en ? 'age-range-block-en' : ''
                }`}
              >
                <input
                  className="me-2"
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  onChange={handleGenderChange}
                />
                <label
                  htmlFor="female"
                  className={`gender-radio ${i18n.language === LANGUAGES.en ? 'wire-one' : ''}`}
                >
                  {t('contact_form.female')}
                </label>
                <input
                  className="mx-2"
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  onChange={handleGenderChange}
                />
                <label
                  htmlFor="male"
                  className={`gender-radio ${i18n.language === LANGUAGES.en ? 'wire-one' : ''}`}
                >
                  {t('contact_form.male')}
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="form-submit-btn">
          <button
            disabled={!age || !gender}
            className={`btn-submit w-100 ${
              i18n.language === LANGUAGES.en ? 'wire-one btn-submit-en' : ''
            }`}
            onClick={() => start()}
          >
            {t('contact_form.start')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
