import React from 'react';
import './style.css';
import { applicationStates, LANGUAGES } from '../../constants';
import { useTranslation } from 'react-i18next';

const Poster = ({ start }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="poster">
        <div className="poster-title">
          <div
            className={`orange ${i18n.language === LANGUAGES.en ? 'wire-one poster-title-en' : ''}`}
          >
            {t('film_name')}
          </div>
        </div>
        <div className="poster-start-button">
          <div className="play-button" onClick={() => start(applicationStates.get_analytics)}></div>
        </div>
        <div className="poster-subtitle-container">
          <span className="poster-subtitle montserrat-arm">{t('interactive_film')}</span>
        </div>
      </div>
    </>
  );
};

export default Poster;
