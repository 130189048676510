import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function RemoveKeyModal({showModal, close, confirm}) {

    return (
        <>
            <Modal show={showModal} onHide={close} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='roboto'>Remove access key</Modal.Title>
                </Modal.Header>
                <Modal.Body className='roboto'>Are you sure?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close} className='roboto'>
                        No
                    </Button>
                    <Button variant="danger" onClick={confirm} className='roboto'>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RemoveKeyModal;