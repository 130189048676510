import React, { useState } from 'react';
import './style.css';
import Info from './Info';
import Team from './Team';
import InformationCards from './InformationCards';
import SendStory from './SendHistory';
import { applicationStates, informationCardTypes, menuItems } from '../../constants';
import { useTranslation } from 'react-i18next';

const Menu = ({ playerRef, playOnHide, changeApplicationState }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [activeInformationCard, setActiveInformationCard] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const handleChangeActiveMenuItem = (item) => {
    setActiveMenuItem((prevState) => {
      if (item !== menuItems.information_cards) {
        setActiveInformationCard('');
      }

      playerRef.current && playerRef.current.pause();

      if (prevState === item) {
        changeApplicationState(applicationStates.started);
        if (playOnHide && !playerRef.current.ended()) {
          playerRef.current.play();
        }
        return null;
      } else {
        changeApplicationState(applicationStates.hide_components);
      }

      return item;
    });
  };

  const toggleMenu = () => {
    setIsOpen((prevState) => {
      setActiveMenuItem(null);
      setActiveInformationCard('');
      return !prevState;
    });
  };

  return (
    <>
      <div className="menu-container">
        <div className="menu-btn" onClick={toggleMenu}></div>
        {isOpen && (
          <>
            <div className="menu-items">
              <div
                className={`menu-item ${
                  activeMenuItem === menuItems.info ? 'info-btn-active' : 'info-btn'
                }`}
                onClick={() => handleChangeActiveMenuItem(menuItems.info)}
              >
                <p
                  className={`menu-item-label montserrat-arm ${
                    activeMenuItem === menuItems.info ? 'menu-item-label-active' : ''
                  }`}
                >
                  {t('menu.about.title')}
                </p>
              </div>
              <div
                className={`menu-item ${
                  activeMenuItem === menuItems.team ? 'team-btn-active' : 'team-btn'
                }`}
                onClick={() => handleChangeActiveMenuItem(menuItems.team)}
              >
                <p
                  className={`menu-item-label montserrat-arm ${
                    activeMenuItem === menuItems.team ? 'menu-item-label-active' : ''
                  }`}
                >
                  {t('menu.team.title')}
                </p>
              </div>
              <div
                className={`menu-item ${
                  activeMenuItem === menuItems.information_cards
                    ? 'information-cards-btn-active'
                    : 'information-cards-btn'
                }`}
                onClick={() => handleChangeActiveMenuItem(menuItems.information_cards)}
              >
                <p
                  className={`menu-item-label montserrat-arm ${
                    activeMenuItem === menuItems.information_cards ? 'menu-item-label-active' : ''
                  }`}
                >
                  {t('menu.information_cards.title')}
                </p>
              </div>
              <div>
                {activeMenuItem === menuItems.information_cards && (
                  <InformationCards
                    informationCardTypes={informationCardTypes}
                    activeInformationCard={activeInformationCard}
                    setActiveInformationCard={setActiveInformationCard}
                  />
                )}
              </div>
              <div
                className={`menu-item ${
                  activeMenuItem === menuItems.share_story
                    ? 'share-story-btn-active'
                    : 'share-story-btn'
                }`}
                onClick={() => handleChangeActiveMenuItem(menuItems.share_story)}
              >
                <p
                  className={`menu-item-label montserrat-arm ${
                    activeMenuItem === menuItems.share_story ? 'menu-item-label-active' : ''
                  }`}
                >
                  {t('menu.share_your_story.title')}
                </p>
              </div>
              <div>
                {activeMenuItem === menuItems.share_story && (
                  <SendStory close={() => handleChangeActiveMenuItem(null)} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {activeMenuItem === menuItems.info && <Info />}
      {activeMenuItem === menuItems.team && <Team />}
      {activeMenuItem === menuItems.information_cards && (
        <>
          {activeInformationCard === informationCardTypes.what_is_bullying && (
            <div className="information-card-container">
              <div className="information-card">
                <div
                  className="information-card-button"
                  onClick={() => setActiveInformationCard('')}
                ></div>
                <p className="information-card-title montserrat-arm">
                  {t('menu.information_cards.types.what_is_bullying')}
                </p>
                <p className="information-card-description montserrat-arm">
                  {t('menu.information_cards.what_is_bullying.text')}
                </p>
                <div className="information-card-outro">
                  <p className="information-card-outro-text montserrat-arm">
                    {t('menu.information_cards.outro')}
                  </p>
                </div>
              </div>
            </div>
          )}
          {activeInformationCard === informationCardTypes.bullying_types && (
            <div className="information-card-container">
              <div className="information-card">
                <div
                  className="information-card-button"
                  onClick={() => setActiveInformationCard('')}
                ></div>
                <p className="information-card-title montserrat-arm">
                  {t('menu.information_cards.types.bullying_types')}
                </p>
                <p className="information-card-description montserrat-arm">
                  <b className="information-card-description-bold montserrat-arm">
                    {t('menu.information_cards.bullying_types.directly.title')}
                  </b>
                  {t('menu.information_cards.bullying_types.directly.description')}
                  <br />
                </p>
                <p className="information-card-description montserrat-arm">
                  <b className="information-card-description-bold montserrat-arm">
                    {t('menu.information_cards.bullying_types.indirectly.title')}
                  </b>
                  {t('menu.information_cards.bullying_types.indirectly.description')}
                  <br />
                </p>
                <p className="information-card-description montserrat-arm">
                  <b className="information-card-description-bold montserrat-arm">
                    {t('menu.information_cards.bullying_types.cyber_bullying.title')}
                  </b>
                  {t('menu.information_cards.bullying_types.cyber_bullying.description')}
                </p>
                <div className="information-card-outro">
                  <p className="information-card-outro-text montserrat-arm">
                    {t('menu.information_cards.outro')}
                  </p>
                </div>
              </div>
            </div>
          )}
          {activeInformationCard === informationCardTypes.bullying_consequences && (
            <div className="information-card-container">
              <div className="information-card">
                <div
                  className="information-card-button"
                  onClick={() => setActiveInformationCard('')}
                ></div>
                <p className="information-card-title montserrat-arm mb-sm-1">
                  {t('menu.information_cards.types.bullying_consequences')}
                </p>
                <p className="information-card-description montserrat-arm mb-sm-1">
                  {t('menu.information_cards.bullying_consequences.title')}
                </p>
                <p
                  className="information-card-description montserrat-arm mb-sm-1"
                  style={{ color: '#000', fontWeight: '700' }}
                >
                  {t('menu.information_cards.bullying_consequences.bullied_child.title')}
                </p>
                <ul className="mb-sm-1">
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullied_child.1')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullied_child.2')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullied_child.3')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullied_child.4')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullied_child.5')}
                  </li>
                </ul>
                <p
                  className="information-card-description montserrat-arm mb-sm-1"
                  style={{ color: '#000', fontWeight: '700' }}
                >
                  {t('menu.information_cards.bullying_consequences.bullying_child.title')}
                </p>
                <ul className="mb-sm-1">
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullying_child.1')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullying_child.2')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullying_child.3')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullying_child.4')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    {t('menu.information_cards.bullying_consequences.bullying_child.5')}
                  </li>
                </ul>
                <div className="information-card-outro">
                  <p className="information-card-outro-text montserrat-arm">
                    {t('menu.information_cards.outro')}
                  </p>
                </div>
              </div>
            </div>
          )}
          {activeInformationCard === informationCardTypes.what_to_do && (
            <div className="information-card-container what-to-do">
              <div className="information-card">
                <div
                  className="information-card-button"
                  onClick={() => setActiveInformationCard('')}
                ></div>
                <p className="information-card-title montserrat-arm mb-sm-1">
                  {t('menu.information_cards.types.what_to_do')}
                </p>
                <ul className="mb-sm-1">
                  <li className="information-card-description montserrat-arm">
                    <b className="information-card-description-bold montserrat-arm">
                      {t('menu.information_cards.what_to_do.speak_up.title')}:
                    </b>
                    {t('menu.information_cards.what_to_do.speak_up.description')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    <b className="information-card-description-bold montserrat-arm">
                      {t('menu.information_cards.what_to_do.protect.title')}:
                    </b>
                    {t('menu.information_cards.what_to_do.protect.description')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    <b className="information-card-description-bold montserrat-arm">
                      {t('menu.information_cards.what_to_do.be_kind.title')}:
                    </b>
                    {t('menu.information_cards.what_to_do.be_kind.description')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    <b className="information-card-description-bold montserrat-arm">
                      {t('menu.information_cards.what_to_do.avoid_cyberbullying.title')}:
                    </b>
                    {t('menu.information_cards.what_to_do.avoid_cyberbullying.description')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    <b className="information-card-description-bold montserrat-arm">
                      {t('menu.information_cards.what_to_do.notify.title')}:
                    </b>
                    {t('menu.information_cards.what_to_do.notify.description')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    <b className="information-card-description-bold montserrat-arm">
                      {t('menu.information_cards.what_to_do.be_compassionate.title')}:
                    </b>
                    {t('menu.information_cards.what_to_do.be_compassionate.description')}
                  </li>
                  <li className="information-card-description montserrat-arm">
                    <b className="information-card-description-bold montserrat-arm">
                      {t('menu.information_cards.what_to_do.be_strong.title')}:
                    </b>
                    {t('menu.information_cards.what_to_do.be_strong.description')}:
                  </li>
                </ul>
                <div className="information-card-outro">
                  <p className="information-card-outro-text montserrat-arm">
                    {t('menu.information_cards.outro')}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Menu;
