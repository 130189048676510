import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { replaceWithBr } from '../../../helpers';

const Info = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="info show-animation">
        <div className="info-title-container mt-5 my-sm-0">
          <p
            className="info-title montserrat-arm"
            dangerouslySetInnerHTML={{ __html: replaceWithBr(t('menu.about.about_project')) }}
          ></p>
        </div>
        <div className="row info-title-container flex-row my-5 my-sm-0">
          <div className="col-12" style={{ padding: 0 }}>
            <p className="info-copyright montserrat-arm">{t('menu.about.copyright')}</p>
          </div>
          <div className="col-xs-1 col-sm-2 col-md-2 p-0 d-flex flex-column">
            <div className="us-flag mt-3 mt-sm-0"></div>
          </div>
          <div className="col p-0">
            <p className="info-title info-partner montserrat-arm">{t('menu.about.partner')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
