export const applicationStates = {
  init: 'INIT',
  get_analytics: 'GET_ANALYTICS',
  started: 'STARTED',
  hide_components: 'HIDE_COMPONENTS',
  end: 'END'
};

export const menuItems = {
  info: 'INFO',
  team: 'TEAM',
  information_cards: 'INFORMATION_CARDS',
  share_story: 'SHARE_STORY'
};

export const informationCardTypes = {
  what_is_bullying: 'what_is_bullying',
  bullying_types: 'bullying_types',
  bullying_consequences: 'bullying_consequences',
  what_to_do: 'what_to_do'
};

export const LANGUAGES = {
  hy: 'hy',
  en: 'en'
};
