import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationHY from './locales/hy.json';
import {LANGUAGES} from "./constants";

const resources = {
  en: {
    translation: translationEN
  },
  hy: {
    translation: translationHY
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || LANGUAGES.hy,
  // keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
