import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="access-denied">
        <div className="access-denied-title mt-xs-1 mt-sm-1 mt-md-2 mt-lg-3">
          <div className="orange">
            «ՈՐՈՇՈՒՄ» ԻՆՏԵՐԱԿՏԻՎ ՖԻԼՄԸ ԴՊՐՈՑՆԵՐՈՒՄ
          </div>
        </div>
        <div className="access-denied-subtitle-container">
          <p className="access-denied-subtitle montserrat-arm">
            <b className="montserrat-arm">«Որոշում» ինտերակտիվ ֆիլմի ցուցադրության հնարավորություն</b><br/><br/>

            Սիրելի՛ ուսուցիչներ, մեծ ուրախությամբ ցանկանում ենք հայտնել, որ մեր թիմի կողմից ստեղծված «Որոշում» ինտերակտիվ ֆիլմն արդեն կարող եք ցուցադրել Ձեր դպրոցներում։<br/><br/>

            Ֆիլմի ցուցադրության հնարավորություն ստանալու համար, խնդրում ենք ուղարկել նամակ մեր էլեկտրոնային հասցեին (g2lyerevan@gmail.com) հետևյալ տեղեկատվությամբ՝<br/>
            • ուսումնական հաստատության անվանումը <br/>
            • ո՞ր դասարաններում է ցուցադրվելու ֆիլմը <br/>
            • ե՞րբ եք պատրաստվում ցուցադրել ֆիլմը <br/>
            <br/>

            Մենք սիրով կտրամադրենք Ձեզ ֆիլմի հղումը, որն անհատական կլինի միայն Ձեր դպրոցի համար` սահմանափակ ժամկետով։ <br/><br/>

            Ֆիլմը դիտելուց հետո կխնդրենք Ձեզ լրացնել մեր կողմից տրամադրվող հարցաշարը ցուցադրման մասին և, հնարավորության դեպքում, նաև կցել նկարներ։<br/><br/>

            Ամբողջ տեղեկությունը կտրամադրվի Ձեզ, երբ ստանաք ֆիլմի հղումը։
          </p>
        </div>
      </div>
    </>
  );
};

export default AccessDenied;
