import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate, useNavigate} from 'react-router-dom';
import { AuthProvider, useAuth } from './auth/AuthContext';
import AdminDashboard from './components/Admin/Dashboard';
import AdminLogin from './components/Admin/SignIn';
import Film from './pages/Film'
import AccessDenied from "./components/AccessDenied";

const PrivateRoute = ({ element }) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? (
        element
    ) : (
        <Navigate to="/admin/login" replace state={{ from: window.location.pathname }} />
    );
};

const App = () => {
    return (
      <AuthProvider>
          <Router>
              <Routes>
                  <Route path="/admin/login" element={<AdminLogin />} />
                  <Route
                      path="/admin"
                      element={<PrivateRoute element={<AdminDashboard />} />}
                  />
                  <Route path="/" element={<Film />} />
                  <Route path="/denied" element={<AccessDenied/>} />
                  <Route path="*" element={<Navigate to="/" />} />
              </Routes>
          </Router>
      </AuthProvider>
  )
};

export default App;
