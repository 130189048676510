import React, {useEffect, useState} from 'react';
import { useAuth } from '../../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
import {getDocs, collection, collectionGroup, addDoc, deleteDoc, doc, endAt, startAfter, updateDoc, limit, query, orderBy} from 'firebase/firestore';
import { db } from "../../../firebaseConfig";
import RemoveKeyModal from "../RemoveKeyModal";
import CreateKeyModal from "../CreateKeyModal";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "react-bootstrap";



const AdminDashboard = () => {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [removeKey, setRemoveKey] = useState(false);
    const [activeToken, setActiveToken] = useState(null);
    const [createKey, setCreateKey] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleShowRemoveAccessKeyModal = (key) => {
        setRemoveKey(true);
        setActiveToken(key);
    }

    const handleHideRemoveAccessKeyModal = () => {
        setRemoveKey(false);
        setActiveToken(null);
    }

    const handleRemoveAccessToken = async () => {
        await deleteAccessToken(activeToken.id)
        setRemoveKey(false);
        setActiveToken(null);

        fetchData();
    }

    const handleShowCreateAccessKeyForm = (accessToken) => {
        setCreateKey(true);

        if (accessToken) {
            setActiveToken({
                ...accessToken,
                startDate: new Date(getDateFromTimeStamp(accessToken.startDate)),
                endDate: new Date(getDateFromTimeStamp(accessToken.endDate)),
            })
        }
    }

    const handleHideCreateAccessKeyForm = () => {
        setCreateKey(false);
        setActiveToken(null);
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const querySnapshot = await getDocs(query(collection(db, 'tokens'), orderBy('title', 'asc')));
            const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            // documents.sort((a,b)=> (a.title > b.title ? 1 : -1))
            setData(documents);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            showErrorToast('Failed to get access keys');
        }
    };

    // TODO review this part
    // const PAGE_SIZE = 10;
    // const [paginatedPosts, setPaginatedPosts] = useState([]);
    // const [lastVisible, setLastVisible] = useState(null);
    // const [firstVisible, setFirstVisible] = useState(null);
    //
    // const handleNextPage = async (e) => {
    //     const documents = await getDocs(query(
    //         collection(db, 'tokens'),
    //         // orderBy('title'),
    //         startAfter(lastVisible),
    //         limit(PAGE_SIZE)
    //     ));
    //
    //     updateState(documents);
    // }
    //
    // const handlePreviousPage = async () => {
    //     console.log(firstVisible)
    //     const documents = await getDocs(query(
    //         collection(db, 'tokens'),
    //         // orderBy('title'),
    //         endAt(firstVisible),
    //         limit(PAGE_SIZE)
    //     ));
    //
    //     console.log(documents)
    //
    //     updateState(documents);
    // }
    //
    // const updateState = (documents) => {
    //     if (!documents.empty) {
    //         const tokens = documents.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //         setPaginatedPosts(tokens);
    //     }
    //     if (documents?.docs[0]) {
    //         setFirstVisible(documents.docs[0]);
    //     }
    //     if (documents?.docs[documents.docs.length - 1]) {
    //         setLastVisible(documents.docs[documents.docs.length - 1]);
    //     }
    // };

    const saveAccessToken = async (data) => {
        try {
            const collectionRef = collection(db, 'tokens');
            const newDocRef = await addDoc(collectionRef, data);
            fetchData();
            showSuccessToast("Access key successfully created!");

            console.log('Document added with ID: ', newDocRef.id);
        } catch (error) {
            console.error('Error adding document: ', error);
            showErrorToast('Failed to save access key');
        }
    };

    const updateAccessToken = async (data) => {
        try {
            // Assuming you have the document ID, replace 'yourDocumentId' with the actual ID
            const docRef = doc(db, 'tokens', data.id);
            await updateDoc(docRef, data);
            fetchData();
            showSuccessToast("Access key successfully updated!");

            console.log('Document updated successfully');
        } catch (error) {
            console.error('Error updating document:', error);
            showErrorToast('Failed to update access key');
        }
    };

    const deleteAccessToken = async (documentId) => {
        try {
            const documentRef = doc(db, 'tokens', documentId);
            await deleteDoc(documentRef);
            fetchData();
            showSuccessToast("Access key successfully removed!");

            console.log('Document deleted successfully');
        } catch (error) {
            showErrorToast('Failed to remove access key');
            console.error('Error deleting document: ', error);

        }
    };

    const getDateFromTimeStamp = (timestamp) => {
        const fireBaseTime = new Date(
            timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
        );
        const date = fireBaseTime.toDateString();
        const time = fireBaseTime.toLocaleTimeString();

        return `${date} ${time}`;

    }

    const handleCopyToken = async (token, lng) => {
        await navigator.clipboard.writeText(`https://bullyinginteractive.am?t=${token}`);
        showSuccessToast("Copied to clipboard!");
    }

    const handleLogout = () => {
        logout()
    }

    function getLanguageByValue(value) {
        switch (value) {
            case "hy":
                return "ARM";
            case "en":
                return "ENG";
        }
    }


    const showSuccessToast = (content) => {
        toast.success(content, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const showErrorToast = (content) => {
        toast.success(content, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/admin/login');
        }
    }, [isAuthenticated, navigate]);


    return (
        <div className="container-fluid dashboard">
            <div className="row">
                <div className="col-2 bg-secondary side-bar d-flex flex-column justify-content-between">
                    <div>
                    <a
                        className="g2l-logo"
                        href="https://global-to-local.se/"
                        target="_blank"
                        rel="noreferrer"
                    ></a>
                    <hr/>
                    </div>
                    <button type="button" className="btn btn-dark roboto mb-3" onClick={handleLogout}>Logout</button>
                </div>
                <div className="col-sm p-3 min-vh-100 bg-body">
                    <h1 className='roboto mb-5'>Access keys</h1>
                    <div>
                        <button className="btn btn-primary roboto mb-3 py-2 px-3" onClick={() => handleShowCreateAccessKeyForm()}>
                            <i className="bi bi-plus-lg me-1"></i>
                            Create
                        </button>
                    </div>
                    {loading ?
                        <Spinner animation="border" role="status" className="spinning-loader mt-5">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    : <>
                    {data.length > 0 ?
                        <>
                            <table className="table table-striped table-bordered roboto">
                                <thead>
                                <tr>
                                    <th className="roboto" scope="col">#</th>
                                    <th className="roboto" scope="col">Title</th>
                                    <th className="roboto" scope="col">Default language</th>
                                    <th className="roboto" scope="col">Start date</th>
                                    <th className="roboto" scope="col">End date</th>
                                    <th className="roboto" scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item, index) =>
                                    <tr key={item.id}>
                                        <th className="roboto" scope="row">{index + 1}</th>
                                        <td className="roboto">{item.title}</td>
                                        <td className="roboto">{getLanguageByValue(item.lng)}</td>
                                        <td className="roboto">{getDateFromTimeStamp(item.startDate)}</td>
                                        <td className="roboto">{getDateFromTimeStamp(item.endDate)}</td>
                                        <td>
                                            <i className="bi bi-copy action-icon mx-2 text-success" onClick={() => handleCopyToken(item.id, item.lng)}></i>
                                            <i className="bi bi-pencil-square action-icon mx-2 text-primary" onClick={(e) => handleShowCreateAccessKeyForm(item)}></i>
                                            <i className="bi bi-trash3 action-icon mx-2 text-danger" onClick={() => handleShowRemoveAccessKeyModal(item)}></i>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </>
                    : <>
                        <div className="text-center bg-light py-5 rounded fs-1">
                            <i className="bi bi-table me-3"></i>
                            No items, press "Create" button to create access key
                        </div>
                      </>
                    }
                    </>}
                    {createKey && <CreateKeyModal show={createKey} accessToken={activeToken} close={handleHideCreateAccessKeyForm} onSubmit={saveAccessToken} onUpdate={updateAccessToken}/>}
                    {removeKey && <RemoveKeyModal showModal={removeKey} close={handleHideRemoveAccessKeyModal} confirm={handleRemoveAccessToken}/>}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AdminDashboard;
