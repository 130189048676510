import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { replaceWithBr } from '../../helpers';
import { LANGUAGES } from '../../constants';

const ChoiceResult = ({
  choice,
  showChoiceResultButton,
  selectedChoices,
  buttonText,
  changeStep
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/*<div className={`choice-result ${showChoiceResult ? "show" : ""}`}>*/}
      <div className="choice-result show-animation">
        <div className="item choice-result-title">
          <div
            className={`d-flex align-baseline ${
              i18n.language === LANGUAGES.en ? 'choice-result-title-container-en' : ''
            }`}
          >
            {/*{choice.is_correct ?*/}
            <span
              className={`correct-title ${
                choice.is_correct ? 'correct-title' : 'incorrect-title'
              } ${i18n.language === LANGUAGES.en ? 'wire-one choice-result-title-en' : ''}`}
            >
              {t(choice.title)}{' '}
            </span>
            <div
              className={`align-self-center ms-5 ${
                choice.is_correct ? 'correct-choice' : 'incorrect-choice'
              }`}
            ></div>
            {/*//     :*/}
            {/*//     <><span className='incorrect-title'>{choice.title} </span><div className='align-self-center ms-5 incorrect-choice'></div></>*/}
            {/*// }*/}
          </div>
        </div>
        <div className="item choice-result-description">
          <span
            className="montserrat-arm"
            dangerouslySetInnerHTML={{ __html: replaceWithBr(t(choice.description)) }}
          ></span>
          {selectedChoices.length < 3 && choice.is_correct && (
            <span
              className="choice-result-right-description montserrat-arm"
              dangerouslySetInnerHTML={{ __html: replaceWithBr(t(choice.correct_description)) }}
            ></span>
          )}
        </div>
        <div className="item choice-result-button">
          {showChoiceResultButton && (
            <button
              className={`btn-choice-result ${
                i18n.language === LANGUAGES.en ? 'wire-one btn-add-character-en' : ''
              }`}
              onClick={() => changeStep()}
            >
              {t(buttonText)}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ChoiceResult;
