import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

const InformationCards = ({
  informationCardTypes,
  activeInformationCard,
  setActiveInformationCard
}) => {
  const { t } = useTranslation();

  const handleSelectInformationCard = (e, informationCardType) => {
    setActiveInformationCard(informationCardType);
  };

  return (
    <>
      <div className="information-cards-container">
        <ul className="information-cards-list">
          <li
            key={informationCardTypes.what_is_bullying}
            style={{
              fontWeight:
                activeInformationCard === informationCardTypes.what_is_bullying ? 600 : 400
            }}
            onClick={(e) => handleSelectInformationCard(e, informationCardTypes.what_is_bullying)}
            className="information-cards-list-item montserrat-arm"
          >
            {t('menu.information_cards.types.what_is_bullying')}
          </li>
          <li
            key={informationCardTypes.bullying_types}
            style={{
              fontWeight: activeInformationCard === informationCardTypes.bullying_types ? 600 : 400
            }}
            onClick={(e) => handleSelectInformationCard(e, informationCardTypes.bullying_types)}
            className="information-cards-list-item montserrat-arm"
          >
            {t('menu.information_cards.types.bullying_types')}
          </li>
          <li
            key={informationCardTypes.bullying_consequences}
            style={{
              fontWeight:
                activeInformationCard === informationCardTypes.bullying_consequences ? 600 : 400
            }}
            onClick={(e) =>
              handleSelectInformationCard(e, informationCardTypes.bullying_consequences)
            }
            className="information-cards-list-item montserrat-arm"
          >
            {t('menu.information_cards.types.bullying_consequences')}
          </li>
          <li
            key={informationCardTypes.what_to_do}
            style={{
              fontWeight: activeInformationCard === informationCardTypes.what_to_do ? 600 : 400
            }}
            onClick={(e) => handleSelectInformationCard(e, informationCardTypes.what_to_do)}
            className="information-cards-list-item montserrat-arm"
          >
            {t('menu.information_cards.types.what_to_do')}
          </li>
        </ul>
      </div>
    </>
  );
};

export default InformationCards;
