import React, { useState } from 'react';
import './style.css';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const SendStory = ({ close }) => {
  const [message, setMessage] = useState('');

  const { t } = useTranslation();

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = async () => {
    try {
      const response = await emailjs.send(
        'service_1kskebs', // Replace with your EmailJS service ID
        'template_asm7s7d', // Replace with your EmailJS template ID
        {
          message: message
        },
        'S6Pvc70TTAcCOiID5' // Replace with your EmailJS user ID
      );

      close(false);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <>
      <div className="send-story">
        <p className="story-title montserrat-arm">{t('menu.share_your_story.description')}</p>
        <div className="send-story-form mb-4">
          <textarea
            className="send-story-textarea"
            onChange={handleChangeMessage}
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <button
            className="send-story-button"
            disabled={message.length === 0}
            onClick={sendEmail}
          ></button>
        </div>
      </div>
    </>
  );
};

export default SendStory;
