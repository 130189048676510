import React, { useRef, useEffect } from 'react';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import '../../style.css';
import PlayButton from '../PlayButton';

export const VideoPlayer = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const customPlayButtonRef = useRef(null);
  const { options, onReady, onEnd, hideComponent, setShowChoiceResult } = props;
  let player;

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);
      videoRef.current.focus();

      player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });

      videoElement.addEventListener('click', () => {
        if (!player.paused()) {
          player.pause();
        } else {
          player.play();
        }
      });

      videoElement.addEventListener('touchstart', () => {
        if (!player.paused()) {
          player.pause();
        } else {
          player.play();
        }
      });

      customPlayButtonRef.current = new PlayButton(player);
      player.addChild(customPlayButtonRef.current);

      player.on('play', handlePlay);
      player.on('pause', handlePause);
      player.on('ended', handleVideoEnd);
      player.on('timeupdate', handleTimeUpdate);
      player.on('loadedmetadata', handleVideoLoaded);

      // You could update an existing player in the `else` block here
      // on prop change, for example:
      // player.on("timeupdate", handleTimeUpdate);
    } else {
      player = playerRef.current;

      player.loop(options.loop);
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const handlePlay = () => {
    customPlayButtonRef.current.hide();
    hideComponent();
  };

  const handlePause = () => {
    customPlayButtonRef.current.show();
  };

  const handleTimeUpdate = () => {
    const remainingTime = playerRef.current.duration() - playerRef.current.currentTime();
    if (remainingTime <= 5) {
      setShowChoiceResult();
    }
  };

  const handleVideoEnd = () => {
    customPlayButtonRef.current.hide();
    onEnd();
  };

  const handleVideoLoaded = () => {
    if (!player.options_.autoplay) {
      customPlayButtonRef.current.show();
    }
  };

  return (
    <div data-vjs-player>
      <div ref={videoRef} className="video-player" />
    </div>
  );
};

export default VideoPlayer;
