import React, { useState } from 'react';
import './style.css';
import episodes from '../../episodes.json';
import { applicationStates } from '../../constants';
import { useTranslation } from 'react-i18next';

const Episodes = ({
  viewedEpisodes,
  playerRef,
  playOnHide,
  chooseEpisode,
  changeApplicationState
}) => {
  const [showEpisodes, setShowEpisodes] = useState(false);

  const { t } = useTranslation();

  const handleClickEpisodes = () => {
    setShowEpisodes((prevState) => {
      playerRef.current && playerRef.current.pause();

      if (!prevState) {
        changeApplicationState(applicationStates.hide_components);
      } else {
        changeApplicationState(applicationStates.started);
        if (playOnHide && !playerRef.current.ended()) {
          playerRef.current.play();
        }
      }

      return !prevState;
    });
  };

  const handleClickEpisode = (e, episode) => {
    chooseEpisode(episode);
    changeApplicationState(applicationStates.started);
    setShowEpisodes(false);
  };

  const isEpisodeUnlocked = (episode) => {
    return viewedEpisodes.includes(episode);
  };

  return (
    <>
      <div className="episodes-container">
        <div className="episodes-btn" onClick={handleClickEpisodes}></div>
      </div>
      {showEpisodes && (
        <>
          <div className="episodes">
            <div className="container-fluid">
              {Object.values(episodes).map((item) => (
                <div className="row" key={item.title}>
                  <p className="episode-title montserrat-arm">{t(item.title)}</p>
                  <div className="episodes-thumbnails">
                    {Object.values(item.episodes).map((item) => (
                      <div key={item.name}>
                        <div
                          className="episode-thumbnail"
                          style={{
                            backgroundImage: `url(${item.image})`,
                            pointerEvents: isEpisodeUnlocked(item.name) ? 'all' : 'none'
                          }}
                          onClick={(e) => handleClickEpisode(e, item.name)}
                        >
                          {!isEpisodeUnlocked(item.name) && (
                            <div className="locked-episode">
                              <div className="episode-lock"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Episodes;
