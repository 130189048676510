import videojs from 'video.js';
import 'video.js/dist/video-js.css';
const Button = videojs.getComponent('Button');

class PlatButton extends Button {
  buildCSSClass() {
    return `vjs-custom-button ${super.buildCSSClass()}`;
  }

  createEl() {
    return super.createEl('button', {
      className: this.buildCSSClass(),
      innerHTML: '<span></span>',
      title: 'Play Button',
      type: 'button'
    });
  }
}

export default PlatButton;
