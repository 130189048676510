import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

const Localization = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = async (language) => {
        await i18n.changeLanguage(language);
        localStorage.setItem('language', language)
    };

    return (
        <>
            <div className="localization">
                <p className={`localization-language roboto ${i18n.language === LANGUAGES.hy ? 'localization-language-active' : ''}`} onClick={() => changeLanguage(LANGUAGES.hy)}>ARM</p>
                <p className={`localization-language roboto ${i18n.language === LANGUAGES.en ? 'localization-language-active' : ''}`} onClick={() => changeLanguage(LANGUAGES.en)}>ENG</p>
            </div>
        </>
    );
};

export default Localization;
