import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

const AddCharacter = ({ text, changeStep }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="add-character">
        <button
          className={`btn-add-character ${
            i18n.language === LANGUAGES.en ? 'wire-one btn-add-character-en' : ''
          }`}
          onClick={() => changeStep()}
        >
          {t(text)}
        </button>
      </div>
    </>
  );
};

export default AddCharacter;
