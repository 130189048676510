import React, { useEffect, useState } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

const Intro = ({ title, subtitle, title2, episode_name, hideComponent, background }) => {
  const [backgroundStyle, setBackgroundStyle] = useState({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (background) {
      setBackgroundStyle({
        background: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      });
    } else {
      setBackgroundStyle({});
    }
  }, [background]);

  return (
    <>
      <div className={`intro ${hideComponent ? 'hide' : ''}`} style={backgroundStyle}>
        <div className="intro-title">
          <div
            className={`intro-title-container ${
              i18n.language === LANGUAGES.en ? 'intro-title-container-en' : ''
            }`}
          >
            <div className={`orange ${i18n.language === LANGUAGES.en ? 'wire-one' : ''}`}>
              {t(title)}
            </div>
            <div className={`sub-title ${i18n.language === LANGUAGES.en ? 'wire-one' : ''}`}>
              &nbsp;{t(subtitle)}
            </div>
          </div>
        </div>
        <div style={{ height: '33%' }}></div>
        <div className="intro-episode">
          <span className="montserrat-arm">{t(episode_name)}</span>
        </div>
      </div>
    </>
  );
};

export default Intro;
