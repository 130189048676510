import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

const MakeChoice = ({ choices, selectedChoices, makeChoice }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="make-choose">
        {choices &&
          choices.map((choice, index) => (
            <div
              className={`item ${selectedChoices.includes(choice.id) ? 'disabled' : ''}`}
              key={index}
              onClick={() => makeChoice(choice)}
            >
              <span className="choice-title"></span>
              <span className={i18n.language === LANGUAGES.en ? 'wire-one' : ''}>
                {t(choice.title)}
              </span>
            </div>
          ))}
      </div>
    </>
  );
};

export default MakeChoice;
