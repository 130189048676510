import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import './style.css'

const AdminLogin = () => {
    const { isAuthenticated, login } = useAuth();
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState(null);

    const handleLogin = async () => {
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
            login();
            navigate('/admin');
        } catch (error) {
            setError(error.message);
            console.error('Login Error:', error.message);
        }
    };

    const handleChangeCredentials = (e) => {
        const { name, value } = e.target
        setCredentials({
            ...credentials,
            [name]: value
        })
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/admin');
        }
    }, [isAuthenticated, navigate]);

    return (
        <>
            <div className="contact-form d-flex flex-column">
                <div className="form">
                    <form action="" className="contact-form-form">
                        <div className="mb-5">
                            <label htmlFor="email" className="form-label field-label montserrat-arm">
                                email
                            </label>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                    <input
                                        placeholder="email"
                                        value={credentials.email}
                                        className="form-control roboto"
                                        onChange={handleChangeCredentials}
                                        type="text"
                                        id="email"
                                        name="email"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="passsword" className="form-label field-label montserrat-arm">
                                password
                            </label>
                            <div>
                                <input
                                    placeholder="password"
                                    value={credentials.password}
                                    className="form-control roboto"
                                    type="password"
                                    id="passsword"
                                    name="password"
                                    onChange={handleChangeCredentials}
                                />
                            </div>
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </form>
                </div>
                <div className="form-submit-btn">
                    <button
                        disabled={!credentials.email || !credentials.password}
                        className="btn-submit w-100 wire-one btn-submit-en"
                        onClick={() => handleLogin()}
                    >
                        login
                    </button>
                </div>
            </div>
        </>
    );
};

export default AdminLogin;
