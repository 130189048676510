import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="team show-animation">
        <div className="team-container">
          <div className="row my-xs-2 my-sm-2 my-md-2 my-lg-4">
            <div className="col-2">
              <p className="team-title montserrat-arm">{t('menu.team.authors.title')}</p>
            </div>
            <div className="col-3 d-flex flex-column">
              <p className="team-member montserrat-arm">{t('menu.team.authors.1')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.authors.2')}</p>
            </div>
          </div>
          <div className="row my-xs-2 my-sm-2 my-md-2 my-lg-4">
            <div className="col-2">
              <p className="team-title montserrat-arm">{t('menu.team.filming_crew.title')}</p>
            </div>
            <div className="col-md-4 col-sm-5 d-flex flex-column">
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.1')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.2')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.3')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.4')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.5')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.6')}</p>
            </div>
            <div className="col-md col-sm-5 d-flex flex-column">
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.7')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.8')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.9')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.10')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.11')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.filming_crew.12')}</p>
            </div>
          </div>
          <div className="row my-xs-2 my-sm-2 my-md-2 my-lg-4">
            <div className="col-2">
              <p className="team-title montserrat-arm">{t('menu.team.acting_in_roles.title')}</p>
            </div>
            <div className="col d-flex flex-column">
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.1')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.2')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.3')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.4')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.5')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.6')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.7')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.8')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.9')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.10')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.11')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.12')}</p>
            </div>
            <div className="col d-flex flex-column">
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.13')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.14')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.15')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.16')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.17')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.18')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.19')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.20')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.21')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.22')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.23')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.24')}</p>
            </div>
            <div className="col d-flex flex-column">
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.25')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.26')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.27')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.28')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.29')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.30')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.31')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.32')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.33')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.34')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.35')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.36')}</p>
            </div>
            <div className="col d-flex flex-column">
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.37')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.38')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.39')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.40')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.41')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.42')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.43')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.44')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.45')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.46')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.47')}</p>
              <p className="team-member montserrat-arm">{t('menu.team.acting_in_roles.48')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
