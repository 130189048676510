import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyC-kQAV0c1-dENa6GT72raFOhvuEbjgCj8',
  authDomain: 'bullyinginteractivefilm.firebaseapp.com',
  projectId: 'bullyinginteractivefilm',
  storageBucket: 'bullyinginteractivefilm.appspot.com',
  messagingSenderId: '718391379351',
  appId: '1:718391379351:web:6a367451bc263a248cf0d2',
  measurementId: 'G-81RZ4SER17'
};

const app = initializeApp(firebaseConfig);
// Export firestore database
export const db = getFirestore(app);
